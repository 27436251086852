import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDivider } from 'vuetify/lib/components/VDivider';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form-validator-wrapper',{on:{"submit":_vm.updateHandler},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
return [_c('scrollable-resource',{scopedSlots:_vm._u([{key:"header",fn:function(){return [(_vm.item)?_c('resource-header',{attrs:{"back-to":{ name: 'webhooks' },"header":_vm.$t('Webhook'),"subheader":_vm.webhook.id},scopedSlots:_vm._u([{key:"actions",fn:function(){return [(_vm.webhook)?_c('div',{staticClass:"d-flex justify-end flex-gap-16"},[(_vm.$canAny('manage,notifications\\webhook', 'delete,notifications\\webhook'))?_c('webhook-action-delete',{attrs:{"value":_vm.webhook,"loading":_vm.loading.delete,"disabled":_vm.loading.update},on:{"update:loading":function($event){return _vm.$set(_vm.loading, "delete", $event)},"onSuccess":_vm.redirectToWebhookGrid}}):_vm._e(),(_vm.$canAny('manage,notifications\\webhook', 'update,notifications\\webhook'))?_c(VBtn,{attrs:{"depressed":"","color":"primary","disabled":!valid || _vm.loading.delete,"loading":_vm.loading.update,"type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t('Update'))+" ")]):_vm._e()],1):_vm._e()]},proxy:true}],null,true)}):_vm._e(),_c(VDivider)]},proxy:true},{key:"scrollable.content",fn:function(){return [(_vm.item)?_c('div',{staticClass:"d-flex flex-column align-center"},[_c('div',{staticClass:"my-6 col-10 d-flex flex-column flex-gap-32"},[_c('readonly-card',[_c('div',{staticClass:"d-flex flex-gap-8"},[_c('span',[_vm._v(_vm._s(_vm.$t('Client ID'))+":")]),(_vm.webhook)?_c(_vm.canAccessWebhookClient ? 'router-link' : 'span',{tag:"router-link",attrs:{"to":{
                    name: 'client-update',
                    params: { organisation: _vm.webhook.client_organisation, store: 0, id: _vm.webhook.client_id }
                  }}},[_vm._v(" "+_vm._s(_vm.webhook.client_id)+" ")]):_vm._e()],1)]),_c('webhook-inputs',{model:{value:(_vm.item),callback:function ($$v) {_vm.item=$$v},expression:"item"}})],1)]):_vm._e()]},proxy:true}],null,true)})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }