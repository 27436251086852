import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VDialog,{attrs:{"max-width":"800","scrollable":""},on:{"input":_vm.setFreshState},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [(_vm.options.length)?_c(VBtn,_vm._g(_vm._b({attrs:{"small":"","depressed":"","color":"primary"}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t('Add macros'))+" ")]):_vm._e()]}}]),model:{value:(_vm.isOpen),callback:function ($$v) {_vm.isOpen=$$v},expression:"isOpen"}},[_c(VCard,[_c(VCardTitle,{staticClass:"d-flex align-center justify-space-between"},[_c('span',[_vm._v(_vm._s(_vm.$t('Add macro')))]),_c(VIcon,{on:{"click":function($event){_vm.isOpen = false}}},[_vm._v("close")])],1),_c(VDivider),_c(VCardText,{staticClass:"d-flex flex-column flex-gap-16 py-4"},[_c(VTextField,{attrs:{"outlined":"","dense":"","hide-details":"","label":_vm.$t('Search option by name')},model:{value:(_vm.searchOption),callback:function ($$v) {_vm.searchOption=$$v},expression:"searchOption"}}),_c(VDataTable,{key:_vm.isOpen,attrs:{"items":_vm.filteredOptions,"headers":_vm.headers,"hide-default-footer":"","items-per-page":-1},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column"},[_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(item.name))]),((item.value || []).length)?_c('span',[_vm._v(_vm._s(_vm.$t('Includes'))+": "+_vm._s(item.value.join(', ')))]):_vm._e(),_c('span',{staticClass:"grey--text text-caption"},[_vm._v(_vm._s(item.code))])])]}},{key:"item.canIncludeElseCondition",fn:function(ref){
var item = ref.item;
return [(Array.isArray(item.value) && item.value.length === 0)?_c(VSwitch,{on:{"change":function($event){return _vm.setIncludeElse(item.code, $event)}}}):_c('span',{staticClass:"grey--text"},[_vm._v(_vm._s(_vm.$t('N/A')))])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c(VBtn,{staticClass:"d-flex flex-gap-16",attrs:{"small":"","depressed":"","color":"primary"},on:{"click":function($event){return _vm.submitMacros(item)}}},[_c('span',[_vm._v(_vm._s(_vm.$t('Append')))]),_c('rtl-chevron-icon')],1)]}}])})],1),_c(VDivider)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }