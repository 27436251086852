var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"d-flex justify-space-between align-center"},[_c('span',[_vm._v(_vm._s(_vm.$t('Body'))+":")]),_c('notification-template-body-macros-dialog',{attrs:{"value":_vm.value},on:{"apply":_vm.onApplyMacro}})],1),_c('html-editor',_vm._b({key:_vm.lastInsert,staticClass:"d-flex flex-column flex-gap-16",attrs:{"quill":_vm.quill},on:{"update:quill":function($event){_vm.quill=$event}},model:{value:(_vm.body),callback:function ($$v) {_vm.body=$$v},expression:"body"}},'html-editor',_vm.$attrs,false)),_c('div',{staticClass:"d-flex flex-column my-4 flex-gap-16"},[_c('span',{staticClass:"grey--text"},[_vm._v(_vm._s(_vm.$t('if-else statement example'))+":")]),_c('div',{staticClass:"d-flex flex-gap-16"},[_c('div',{staticClass:"d-flex flex-column text-caption grey--text"},[_c('pre',[_vm._v(_vm._s(_vm.ifElseExplainer.map(function (ref) {
	var line = ref.line;

	return line;
}).join('\n')))])]),_c('div',{staticClass:"d-flex flex-column text-caption grey--text"},[_c('pre',[_vm._v(_vm._s(_vm.ifElseExplainer.map(function (ref) {
	var explainer = ref.explainer;

	return explainer;
}).join('\n')))])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }