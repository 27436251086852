var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('grid-layout',_vm._b({on:{"paramsChanged":_vm.reloadCollection},scopedSlots:_vm._u([{key:"item.code",fn:function(ref){
var item = ref.item;
return [_c(_vm.canAccessNotificationTemplate ? 'router-link' : 'span',{tag:"router-link",attrs:{"to":{ name: 'notification-template-update', params: { id: item.id } }}},[_vm._v(" "+_vm._s(item.code)+" ")])]}},{key:"item.channel",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-capitalize"},[(item.channel === 'vonage')?_c('span',[_vm._v(_vm._s(_vm.$t('SMS')))]):_c('span',[_vm._v(_vm._s(item.channel))])])]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$format.timezone.getFormattedTime(item.created_at)))])]}},{key:"item.updated_at",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$format.timezone.getFormattedTime(item.created_at)))])]}},{key:"item.edit",fn:function(ref){
var item = ref.item;
return [_c('button-action',{attrs:{"icon-name":"edit","icon":"","label":_vm.$t('Edit'),"disabled":_vm.grid.loading,"to":{ name: 'notification-template-update', params: { id: item.id } }}})]}}])},'grid-layout',{ grid: _vm.grid, params: _vm.params, options: _vm.options, title: _vm.$t('Notification Templates') },false))}
var staticRenderFns = []

export { render, staticRenderFns }