import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('grid-layout',_vm._b({on:{"paramsChanged":_vm.reloadCollection},scopedSlots:_vm._u([{key:"actions",fn:function(){return [(_vm.$canAny('manage,notifications\\webhook', 'create,notifications\\webhook'))?_c(VBtn,{attrs:{"depressed":"","color":"primary","to":{ name: 'webhook-create' }}},[_vm._v(" "+_vm._s(_vm.$t('Create Webhook'))+" ")]):_vm._e()]},proxy:true},{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c(_vm.canAccessWebhook ? 'router-link' : 'span',{tag:"router-link",attrs:{"to":{ name: 'webhook-update', params: { id: item.id } }}},[_vm._v(" "+_vm._s(_vm.getShortId(item.id))+" ")])]}},{key:"item.events",fn:function(ref){
var item = ref.item;
return [(item.events)?_c('div',{staticClass:"d-flex flex-wrap flex-gap-8 my-2"},_vm._l((item.events),function(event,index){return _c(VChip,{key:("event-" + index),attrs:{"small":""}},[_vm._v(" "+_vm._s(event)+" ")])}),1):_vm._e()]}},{key:"item.url",fn:function(ref){
var item = ref.item;
return [_c('external-link',{attrs:{"label":item.url,"value":item.url}})]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$format.timezone.getFormattedTime(item.created_at)))])]}},{key:"item.updated_at",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$format.timezone.getFormattedTime(item.created_at)))])]}},{key:"item.edit",fn:function(ref){
var item = ref.item;
return [_c('button-action',{attrs:{"icon-name":"edit","icon":"","label":_vm.$t('Edit'),"disabled":_vm.grid.loading,"to":{ name: 'webhook-update', params: { id: item.id } }}})]}},{key:"item.delete",fn:function(ref){
var item = ref.item;
return [_c('webhook-action-delete',{attrs:{"icon":"","value":item},on:{"onSuccess":_vm.onDeleteSuccess}})]}}])},'grid-layout',{ grid: _vm.grid, params: _vm.params, options: _vm.options, title: _vm.$t('Webhooks') },false))}
var staticRenderFns = []

export { render, staticRenderFns }